window.Client = {
	init: function() {
		globalX = screen.width;
	},

	onRender: function(screenId) {
		if (globalX) {
		    if(globalX <= 550) {
                document.getElementById("id-e4f8eedb").value = "Mobile";
		    }
        }
	}
}